import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow, Navigation, Pagination } from 'swiper';
import styled from 'styled-components';

import Media from '~/components/Storyblok/elements/Media';
import { storyblokEditable } from '@storyblok/react';
import useWindowSize from '~/hooks/useWindowSize';

SwiperCore.use([EffectCoverflow, Navigation, Pagination]);

export default function TestimonialMediaSlider({ blok }) {
  const layout = blok.layout || 'default';
  const [shown, setShown] = useState(0);
  if (!blok.testimonials) return null;

  if (layout === 'slides') return <TestimonialSlider blok={blok} />;

  const shownTestimonial = blok.testimonials[shown];

  return (
    <div>
      <div className="flex flex-col md:flex-row text-center items-center">
        {blok.testimonials.map((testimonial, i) => (
          <div
            onClick={() => setShown(i)}
            className={`px-6 lg:px-8 pb-6 flex flex-col items-center md:border-b-4 select-none ${i === shown ? '' : 'cursor-pointer'}`}
            style={{ borderColor: i === shown ? '#F3B545' : 'transparent' }}
            key={testimonial._uid}
            {...storyblokEditable(testimonial)}>
            <div className="mx-auto">
              <svg width="24" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.795 19.252c-1.74 0-3.056-.587-3.95-1.763-.893-1.175-1.34-2.656-1.34-4.443 0-2.961.917-5.641 2.75-8.039C5.137 2.562 7.559.893 10.52 0l.493 1.058c-2.35.987-4.184 2.28-5.5 3.878C4.196 6.488 3.49 8.463 3.397 10.86c.658-.329 1.504-.493 2.539-.493 1.222 0 2.256.423 3.103 1.269.846.8 1.27 1.81 1.27 3.032 0 1.27-.424 2.351-1.27 3.244-.8.894-1.88 1.34-3.244 1.34zm12.764 0c-1.74 0-3.056-.587-3.95-1.763-.892-1.175-1.34-2.656-1.34-4.443 0-2.961.918-5.641 2.751-8.039C17.9 2.562 20.322.893 23.284 0l.494 1.058c-2.351.987-4.185 2.28-5.501 3.878-1.317 1.552-2.022 3.527-2.116 5.924.659-.329 1.505-.493 2.54-.493 1.221 0 2.256.423 3.102 1.269.846.8 1.27 1.81 1.27 3.032 0 1.27-.424 2.351-1.27 3.244-.8.894-1.88 1.34-3.244 1.34z"
                  fill="#F3B545"
                />
              </svg>
            </div>
            <div className="py-4 font-medium text-20px" dangerouslySetInnerHTML={{ __html: testimonial.testimonial }} />
            <div dangerouslySetInnerHTML={{ __html: testimonial.citation }} />
            <div className="md:hidden w-full mt-4">{testimonial.citationImage ? <Media blok={{ asset: testimonial.citationImage, modal: testimonial.modal }} /> : null}</div>
          </div>
        ))}
      </div>

      <div className="hidden md:block">{shownTestimonial.citationImage ? <Media blok={{ asset: shownTestimonial.citationImage, modal: shownTestimonial.modal }} /> : null}</div>
    </div>
  );
}

const SliderContainer = styled.div`
  .swiper-slide {
    width: 100%;
  }

  @media screen and (min-width: 1280px) {
    .swiper-slide {
      width: 80%;
    }
  }
`;

function TestimonialSlider({ blok }) {
  if (!blok.slidesLayout || blok?.slidesLayout === 'block') {
    return <BlockTestimonialSlider blok={blok} />;
  }

  if (blok?.slidesLayout === 'inline') {
    return <InlineTestimonialSlider blok={blok} />;
  }
}

function BlockTestimonialSlider({ blok }) {
  const { width } = useWindowSize();

  return (
    <SliderContainer>
      <Swiper loop={true} slidesPerView={width > 768 ? 1.5 : 1} spaceBetween={width > 768 ? 40 : 20} centeredSlides={true} className="overflow-visible">
        {blok.testimonials.map((testimonial, i) => (
          <SwiperSlide key={`block-testimonial${i}`} className="w-full">
            {({ isActive }) => (
              <div className={`${!isActive && 'opacity-50'} transition-opacity duration-150 cursor-pointer`}>
                <div className="w-full overflow-hidden" style={{ aspectRatio: '16/9' }}>
                  <div className="img-cover">{testimonial.citationImage ? <Media blok={{ asset: testimonial.citationImage, modal: testimonial.modal }} /> : null}</div>
                </div>
                <div className="mt-12 max-w-lg px-2 md:px-6 mx-auto text-center flex flex-col items-center">
                  <img src="https://a-us.storyblok.com/f/1001720/x/d171070a12/quotes.svg" alt="" />
                  <div className="py-4 font-medium text-20px" dangerouslySetInnerHTML={{ __html: testimonial.testimonial }} />
                  <div dangerouslySetInnerHTML={{ __html: testimonial.citation }} />
                </div>
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </SliderContainer>
  );
}

const InlineSliderContainer = styled.div`
  .swiper-wrapper {
    display: flex;
  }
  .swiper-slide {
    height: auto;
  }

  .inline-cite-image {
    img {
      object-fit: cover;
    }
    span {
      height: 100% !important;
    }
  }

  .pagi-buttons {
    svg {
      cursor: pointer;
      transition: fill 0.2s;
    }

    svg rect {
      transition: fill 0.2s;
    }

    svg:hover rect {
      stroke-opacity: 0;
      fill: #f3b545;
    }
  }
`;
function InlineTestimonialSlider({ blok }) {
  const { width } = useWindowSize();
  const [swiper, setSwiper] = useState(null);

  return (
    <InlineSliderContainer className="inline-slider">
      <Swiper
        loop={true}
        modules={[EffectCoverflow, Navigation, Pagination]}
        effect="coverflow"
        coverflowEffect={{
          slideShadows: false
        }}
        slidesPerView={width > 640 ? 1.5 : 1.25}
        spaceBetween={width > 1000 ? 40 : 10}
        centeredSlides={true}
        className="overflow-visible"
        onSwiper={swiper => setSwiper(swiper)}
        >
        {blok.testimonials.map(testimonial => (
          <SwiperSlide className="w-full p-8  lg:py-12 bg-semi-grey flex flex-col justify-center">
            {({ isActive }) => (
              <div className={`${!isActive && 'opacity-50'} transition-opacity duration-150 cursor-pointer flex `}>
                <div className="hidden md:block w-4/12 overflow-hidden mr-6 lg:mr-10" style={{ aspectRatio: '216/288', maxWidth: '216px' }}>
                  <div className="img-cover h-full">
                    {testimonial.citationImage ? <Media className="h-full inline-cite-image" blok={{ asset: testimonial.citationImage, modal: testimonial.modal }} /> : null}
                  </div>
                </div>

                <div className="md:w-8/12 self-center">
                  <img src="https://a-us.storyblok.com/f/1001720/x/d171070a12/quotes.svg" alt="" />
                  <div className="py-5 font-medium text-20px sm:text-24px leading-snug" dangerouslySetInnerHTML={{ __html: testimonial.testimonial }} />
                  <div dangerouslySetInnerHTML={{ __html: testimonial.citation }} />
                </div>
              </div>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="flex space-x-3 pagi-buttons justify-center mt-12">
        <svg onClick={()=>swiper.slidePrev()} className="transform rotate-180" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="36" height="36" rx="4" fill="none" stroke="#041B29" strokeOpacity="0.1" />
          <path d="M12.167 18H23.8337" stroke="black" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M18 12.1667L23.8333 18.0001L18 23.8334" stroke="black" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

        <svg onClick={()=>swiper.slideNext()}  width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect  width="36" height="36" rx="4" fill="none" stroke="#041B29" strokeOpacity="0.1" />
          <path d="M12.167 18H23.8337" stroke="black" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M18 12.1667L23.8333 18.0001L18 23.8334" stroke="black" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
    </InlineSliderContainer>
  );
}
